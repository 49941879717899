"use client";

import { ReactNode } from "react";

import { Accordion } from "@sikt/sds-accordion";

import css from "./guidance.module.css";

interface BlockProps {
  title: string;
  content: ReactNode;
}

const Item = ({ title, content }: BlockProps) => (
  <Accordion title={title} size="small" className={css.item}>
    {content}
  </Accordion>
);

export default Item;
